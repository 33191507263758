
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import {
  defineComponent, ref, Ref, computed, onMounted, nextTick, toRefs,
  onUnmounted
} from 'vue';
import DashboardLatestBlogsSkeleton from '@/views/home/components/DashboardLatestBlogsSkeleton.vue';
import ByteCookieIcon from '@/components/svg/logo/logo-circles-only.svg?inline';
import Chevron from '@/components/svg-components/Chevron.vue';
import { RawLocation } from 'vue-router';
import useI18n from '@/mixins/useI18n';
import { BlogEntry } from '@/views/blog/models/Blog';
import { getBlogEntries } from '@/services/Blog/Api';
import prepareBlog from '@/utils/sortBlog';
import { truncate } from 'lodash';

export default defineComponent({
  components: {
    DashboardLatestBlogsSkeleton,
    ByteCookieIcon,
    Chevron
  },
  props: {
    blogType: {
      type: String,
      default: 'articles'
    },
    isLarge: {
      type: Boolean,
      default: false
    }
  },
  emits: ['no-articles'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { getFormattedDate, getFormattedTimeByAge } = useFormattedDateTime();
    const { blogType } = toRefs(props);

    const isNewsBlog = computed(() => blogType.value === 'news');
    const linkToBlog: Ref<RawLocation> = ref({ name: 'blog' });
    const featureBlog: Ref<BlogEntry | null> = ref(null);
    const featuredBlogEl: Ref<HTMLElement | null> = ref(null);
    const blogList: Ref<BlogEntry[]> = ref([]);
    const isLoading: Ref<boolean> = ref(true);

    const featureBlogLink = computed(() => (featureBlog.value ? {
      name: 'blog articles article',
      params: {
        articleId: featureBlog.value.externalId
      }
    } : {}));

    const loadBlogArticles = async (blogType: string) => {
      let entryType = 'ARTICLE';
      switch (blogType) {
        case 'news':
          entryType = 'BC_NEWS';
          break;
        default:
      }
      const { blogEntries } = await getBlogEntries(entryType);

      const sortedBlogEntries = prepareBlog(blogEntries, entryType).slice(0, 4);

      if (sortedBlogEntries.length > 0) {
        [featureBlog.value, ...blogList.value] = sortedBlogEntries;
      }
    };

    function getArticleLink(articleId = ''): RawLocation {
      return {
        name: 'blog articles article',
        params: {
          articleId
        }
      };
    }

    function truncateTitle(title: string): string {
      return truncate(title, {
        length: 200,
        separator: '...'
      });
    }

    function formatDate(timestamp: string, dateformat: 'short' | 'diff' = 'diff'): string {
      if (dateformat === 'short') return getFormattedDate(timestamp);
      return getFormattedTimeByAge(timestamp);
    }

    function setBlogList(): void {
      const featuredBlogElHeight = featuredBlogEl.value?.clientHeight;

      if (!featuredBlogElHeight) {
        return;
      }

      if (featuredBlogElHeight >= 360) {
        blogList.value = blogList.value.slice(0, 1);
      } else if (featuredBlogElHeight >= 334) {
        blogList.value = blogList.value.slice(0, 2);
      }
    }

    onMounted(async () => {
      await loadBlogArticles(blogType.value);
      isLoading.value = false;

      nextTick(() => {
        setBlogList();
      });

      if (!blogList.value.length && !featureBlog.value) {
        emit('no-articles');
      }
      window.addEventListener('resize', setBlogList);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', setBlogList);
    });

    return {
      linkToBlog,
      isNewsBlog,
      featureBlog,
      featuredBlogEl,
      featureBlogLink,
      getArticleLink,
      blogList,
      isLoading,
      truncateTitle,
      formatDate,
      t
    };
  }
});
