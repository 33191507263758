var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.BCXTable,{staticClass:"my-projects-contract-file-table",attrs:{"rows":_setup.contractFilesRows,"field-names":_setup.fieldNames,"sortable-fields":_setup.fieldNames,"header-fields":_setup.fieldNames,"header-titles":[
    _vm.$t('contracts.contract.contract.details.table.columns.nr').toString(),
    _vm.$t('contracts.contract.contract.details.table.columns.date').toString(),
    _vm.$t('contracts.contract.contract.details.table.columns.from').toString()
  ],"default-sort-field":_setup.fieldNames[0],"default-sort-ascending":true,"sort-value-callback":_setup.sortCallback,"display-value-callback":_setup.displayCallback,"template-columns":"1.2fr 2.2fr 7.5fr","mobile-template-columns":"2fr 5.5fr","mobile-grid-areas":'versionNo created created \n uploader uploader uploader'},scopedSlots:_vm._u([{key:"table-cell",fn:function({fieldName, row, displayValue, isMobile }){return [_c('div',{key:`${fieldName}-${row.id}`,class:[`c-cell bcx-truncated-text c-${fieldName}`, {
        'c-with-buttons': (fieldName==='uploader' && !isMobile) || (fieldName==='created' && isMobile),
      }]},[_c('span',[_vm._v(" "+_vm._s(displayValue)+" ")]),((fieldName==='uploader' && !isMobile) || (fieldName==='created' && isMobile))?_c(_setup.MyProjectsContractFileTableButtons,{attrs:{"is-deletable":row.deletable,"is-file-seen":row.hasItBeenSeen},on:{"delete-file":function($event){return _setup.deleteFile(row.id)},"download-file":function($event){return _setup.downloadFile(row.id)}}}):_vm._e()],1)]}},{key:"empty",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('contracts.contract.contract.details.table.empty'))+" ")]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }