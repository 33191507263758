import { Contract, NewContractRequest } from '@/models/Contracts';
import {
  getAllContracts, postNewContract
} from '@/services/ContractService';
import { createGlobalState } from '@vueuse/core';
import { reactive, ref } from 'vue';

const useContractOverview = createGlobalState(() => {
  const allContracts = ref<Contract[]>([]);
  const tmpContracts = ref<Contract[]>([]);
  const isContractOverviewLoading = ref(false);

  const hasContractOverviewChanges = ref(false);
  const checkForChanges = () => {
    const allContractsSorted = [...allContracts.value].sort((a, b) => a.bcReferenceId.localeCompare(b.bcReferenceId));
    const tmpContractsSorted = [...tmpContracts.value].sort((a, b) => a.bcReferenceId.localeCompare(b.bcReferenceId));

    hasContractOverviewChanges.value = allContractsSorted.length !== tmpContractsSorted.length
      || JSON.stringify(allContractsSorted) !== JSON.stringify(tmpContractsSorted);
  };
  const updateContractsFromTmp = () => {
    allContracts.value = tmpContracts.value;
    hasContractOverviewChanges.value = false;
  };

  const fetchContracts = async (forceUpdate = false) => {
    isContractOverviewLoading.value = true;
    tmpContracts.value = await getAllContracts();
    if (allContracts.value.length === 0 || forceUpdate) {
      allContracts.value = tmpContracts.value;
    }
    checkForChanges();
    isContractOverviewLoading.value = false;
  };

  const newContractProperties = reactive<NewContractRequest>({
    client: undefined,
    contractPartnerId: '',
    message: ''
  });

  const submitNewContract = async () => {
    const contract = await postNewContract(newContractProperties);
    allContracts.value.push(contract);
    newContractProperties.client = undefined;
    newContractProperties.contractPartnerId = '';
    newContractProperties.message = '';
    return contract;
  };

  return {
    fetchContracts,
    hasContractOverviewChanges,
    updateContractsFromTmp,
    isContractOverviewLoading,
    allContracts,
    newContractProperties,
    submitNewContract,
  };
});

export default useContractOverview;
