import { AxiosResponse } from 'axios';
import axios from '@/plugins/axios';
import {
  Contract, ContractUpdateAddress, ContractUpdateNotes, NewContractRequest, CreateContractDocumentRequest,
  ContractDocument,
  ContractDocumentFile,
  ContractDetail
} from '@/models/Contracts';
import { AcceptOfferRequest, ProjectOfferBase } from '@/models/MyProjects';

export async function getAllContracts(): Promise<Contract[]> {
  return (await axios.get('/v1/contracts')).data;
}
export async function postNewContract(contractRequest: NewContractRequest): Promise<Contract> {
  return (await axios.post('/v1/contracts', contractRequest)).data;
}
export async function createContractFromOffer(projectId: string, projectOfferId: string, acceptOfferRequest: AcceptOfferRequest): Promise<Contract> {
  return (await axios.post(`/v1/projects/${projectId}/offers/${projectOfferId}/contracts`, acceptOfferRequest)).data;
}
export async function getContract(contractId: string): Promise<ContractDetail> {
  return (await axios.get(`/v1/contracts/${contractId}`)).data;
}
export async function updateContract(contractId: string, projectOffer: ProjectOfferBase):Promise<AxiosResponse> {
  return axios.put(`/v1/contracts/${contractId}`, projectOffer);
}
export async function deleteContract(contractId: string): Promise<AxiosResponse> {
  return axios.delete(`/v1/contracts/${contractId}`);
}
export async function acceptContract(contractId: string): Promise<AxiosResponse> {
  return axios.put(`/v1/contracts/${contractId}/accept`);
}
export async function updateContractAddress(contractId: string, address: ContractUpdateAddress): Promise<AxiosResponse> {
  return axios.put(`/v1/contracts/${contractId}/address`, address);
}
export async function declineContract(contractId: string): Promise<AxiosResponse> {
  return axios.put(`/v1/contracts/${contractId}/decline`);
}
export async function addNotesToContract(contractId: string, updateNotes: ContractUpdateNotes): Promise<AxiosResponse> {
  return axios.put(`/v1/contracts/${contractId}/notes`, updateNotes);
}
export async function updateContractReference(contractId: string, reference: string): Promise<AxiosResponse> {
  return axios.put(`/v1/contracts/${contractId}/reference`, { reference });
}
export async function reopenContract(contractId: string): Promise<AxiosResponse> {
  return axios.put(`/v1/contracts/${contractId}/reopen`);
}
export async function postContractOffer(contractId: string, projectOffer: ProjectOfferBase): Promise<AxiosResponse> {
  return axios.post(`/v1/contracts/${contractId}/offers`, projectOffer);
}
export async function putContractOffer(contractId: string, projectOffer: ProjectOfferBase): Promise<AxiosResponse> {
  return axios.put(`/v1/contracts/${contractId}/offer`, projectOffer);
}

export async function createContractDocument(contractId: string, createDocumentRequest: CreateContractDocumentRequest): Promise<ContractDocument> {
  return (await axios.post(`/v1/contracts/${contractId}/documents`, createDocumentRequest)).data;
}
export async function updateContractDocument(contractId: string, documentId: string, document: CreateContractDocumentRequest): Promise<ContractDocument> {
  return (await axios.put(`/v1/contracts/${contractId}/documents/${documentId}`, document)).data;
}
export async function deleteContractDocument(contractId: string, documentId: string): Promise<AxiosResponse> {
  return axios.delete(`/v1/contracts/${contractId}/documents/${documentId}`);
}
export async function getContractDocumentFile(contractId: string, documentId: string, fileId: string): Promise<ContractDocumentFile> {
  return (await axios.get(`/v1/contracts/${contractId}/documents/${documentId}/file/${fileId}`)).data;
}
export async function deleteContractDocumentFile(contractId: string, documentId: string, fileId: string): Promise<AxiosResponse> {
  return axios.delete(`/v1/contracts/${contractId}/documents/${documentId}/file/${fileId}`);
}
export async function createContractDocumentFile(contractId: string, documentId: string, file: string, filename: string): Promise<ContractDocumentFile> {
  const url = `/v1/contracts/${contractId}/documents/${documentId}/files`;
  return (await axios.post(url, { data: file, filename })).data;
}
export async function signContractDocument(contractId: string, documentId: string): Promise<ContractDocument> {
  return (await axios.put(`/v1/contracts/${contractId}/documents/${documentId}/signatures`)).data;
}
export async function deleteContractDocumentSignature(contractId: string, documentId: string): Promise<ContractDocument> {
  return (await axios.delete(`/v1/contracts/${contractId}/documents/${documentId}/signatures`)).data;
}
