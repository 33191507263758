var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"table__container"},[_c('div',{staticClass:"table__filters"},[_c('BCXMultiselectFilter',{attrs:{"items":_vm.entryTypeFilter.states.map((state) => ({
        label: state,
        value: state
      })),"title":_vm.$t('blog.type').toString(),"default-value":_vm.entryTypeFilter.states},model:{value:(_vm.typeFilterValues),callback:function ($$v) {_vm.typeFilterValues=$$v},expression:"typeFilterValues"}}),_c('BCXMultiselectFilter',{attrs:{"items":_vm.entryStatusFilter.states.map((state) => ({
        label: state,
        value: state
      })),"title":_vm.$t('blog.status').toString(),"default-value":_vm.entryStatusFilter.states},model:{value:(_vm.statusFilterValues),callback:function ($$v) {_vm.statusFilterValues=$$v},expression:"statusFilterValues"}})],1),_c('table',{staticClass:"table__entries"},[_c('thead',{staticClass:"table__header"},[_c('tr',_vm._l((_vm.tableHead),function(head,index){return _c('th',{key:index,class:{ 'rotate': _vm.sortStateByKey(head.key), 'active': _vm.sortActive(head.key) },on:{"click":function($event){return _vm.sortData(head.key)}}},[_vm._v(" "+_vm._s(head.label)+" ")])}),0)]),_c('tbody',{staticClass:"table__body"},_vm._l((_vm.finalRows),function(entry,index){return _c('tr',{key:index,on:{"click":function($event){return _vm.editEntry(entry)}}},[_c('td',[_c('span',[_vm._v(_vm._s(entry.type))])]),_c('td',[_c('span',[_vm._v(_vm._s(entry.title))])]),_c('td',[_c('span',{class:entry.status.toLowerCase()},[_vm._v(_vm._s(entry.status))])]),_c('td',[_c('span',[_vm._v(_vm._s(entry.lastUpdated))])]),_c('td',[_c('span',[_vm._v(_vm._s(entry.position))])]),_c('td',[_c('span',{staticClass:"audience-column",attrs:{"title":_vm.getAudience(entry.audience)}},[_vm._v(_vm._s(_vm.getAudience(entry.audience)))])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }