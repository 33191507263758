import { render, staticRenderFns } from "./AddGuest.vue?vue&type=template&id=b3118f1c&scoped=true"
import script from "./AddGuest.vue?vue&type=script&lang=ts&setup=true"
export * from "./AddGuest.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./AddGuest.vue?vue&type=style&index=0&id=b3118f1c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3118f1c",
  null
  
)

export default component.exports