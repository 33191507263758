import { SelectItem } from '@/models/SelectItem';

export interface BcxFormFieldTextareaOptions {
  height?: number;
  enlargedHeight?: number;
  maxLength?: number;
}

export interface BcxFromFieldTextOptions {
  maxLength?: number;
  hideCharsLeft?: boolean;
  min?: number;
  max?: number;
}

export interface BcxFormFieldDateOptions {
  earliest?: string | Date;
  latest?: string | Date;
  secondary?: boolean;
}

export interface BcxFormFieldCurrencyOptions {
  symbol?: string;
  decimals?: number;
}

export enum BcxFormValidation {
  EMAIL = 'email',
  ZIP = 'zip'
}

export type BcxFormCustomValidation = (value: string, data: Record<string, string>) => string | null;

export interface BcxFormFieldErrorMessage {
  message: string;
  isVisible: boolean;
}

export interface BcxFormValueField {
  name: string;
  label: string;
  labelHint?: string; // small text right after the label
  width?: string;
  justify?: 'start' | 'end';
}

export interface BcxFormField extends BcxFormValueField {
  type: 'text' | 'textarea' | 'select' | 'checkbox' | 'date' | 'plaintext' | 'number' | 'currency';
  fieldWidth?: string;
  placeholder?: string;
  required?: boolean;
  validations?: Array<BcxFormValidation | BcxFormCustomValidation>;
  disabled?: boolean;
  suffix?: string;
  options?: SelectItem[];
  textareaOptions?: BcxFormFieldTextareaOptions;
  dateOptions?: BcxFormFieldDateOptions;
  currencyOptions?: BcxFormFieldCurrencyOptions;
  errorMessage?: BcxFormFieldErrorMessage;
  triggerValidation?: boolean; // used in reactive field structures to trigger re-validation of field (for example, when combined with other fields)
  justify?: 'start' | 'end';
  sonderlocke?: 'primary-placeholder'; // Welcome to the zoo...
  hint?: string;
  textOptions?: BcxFromFieldTextOptions;
  isTextareaEnlarged?: boolean;
}
