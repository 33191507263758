
import { getBlogEntries } from '@/services/Blog/Api';
import BlogEntryCard from '@/views/blog/components/BlogEntry.vue';
import { defineComponent, onMounted, ref } from 'vue';
import prepareBlog from '@/utils/sortBlog';
import { BlogEntry } from '../models/Blog';

export default defineComponent({
  components: {
    BlogEntryCard
  },
  setup() {
    const articles = ref<BlogEntry[]>([]);
    const news = ref<BlogEntry[]>([]);

    const loadBlogEntries = async () => {
      const { blogEntries } = await getBlogEntries();
      articles.value = prepareBlog(blogEntries, 'ARTICLE');
      news.value = prepareBlog(blogEntries, 'BC_NEWS');
    };

    onMounted(loadBlogEntries);

    return { articles, news };
  }
});
