
import {
  computed, ComputedRef, defineComponent, ref, toRefs
} from 'vue';
import { reactiveComputed, useLocalStorage } from '@vueuse/core';
import useI18n from '@/mixins/useI18n';
import { UserRole } from '@/models/User';
import { BcxTableFilter } from '@/models/BcxTable';
import BCXMultiselectFilter from '@/components/molecules/BCXMultiselectFilter.vue';
import useBcxTableSearchFilterSort, { BcxTableSearchFilterSortOptions } from '@/mixins/useBcxTableSearchFilterSort';
import { BlogEntriesAdmin, BlogForm } from '../models/Blog';

export default defineComponent({
  components: {
    BCXMultiselectFilter
  },
  props: {
    entries: {
      type: Object as () => BlogEntriesAdmin,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();
    const sortKey = ref('lastUpdated');
    const sortDesc = ref(false);
    const { entries } = toRefs(props);
    const sortData = (key: string, keepDirection = false) => {
      if (key === sortKey.value && !keepDirection) {
        sortDesc.value = !sortDesc.value;
      }
      switch (key) {
        case 'position':
          if (sortDesc.value) {
            entries.value.blogEntries.sort((a: BlogForm, b: BlogForm) => a.position - b.position);
          } else {
            entries.value.blogEntries.sort((a: BlogForm, b: BlogForm) => b.position - a.position);
          }
          break;
        default:
          if (sortDesc.value) {
            entries.value.blogEntries.sort((a: Record<string, any>, b: Record<string, any>) => (a[key] < b[key] ? 1 : -1));
          } else {
            entries.value.blogEntries.sort((a: Record<string, any>, b: Record<string, any>) => (a[key] > b[key] ? 1 : -1));
          }
          break;
      }
      sortKey.value = key;
    };
    const sortableList = computed(() => {
      sortData(sortKey.value, true);
      return entries.value;
    });
    const sortStateByKey = (key: string) => sortDesc.value && sortKey.value === key;
    const sortActive = (key: string) => sortKey.value === key;
    const tableHead = [
      {
        key: 'type',
        label: t('blog.type')
      },
      {
        key: 'title',
        label: t('blog.title')
      },
      {
        key: 'status',
        label: t('blog.status')
      },
      {
        key: 'lastUpdated',
        label: t('blog.changed')
      },
      {
        key: 'position',
        label: 'P'
      },
      {
        key: 'audience',
        label: t('blog.audience')
      }
    ];

    const getAudience = (audience: UserRole[] | undefined) => {
      if (!audience) {
        return '';
      }
      return audience.join(', ');
    };

    // #region FILTERS
    // STATUS FILTER
    const entryStatusFilterStates = ['PUBLISHED', 'DRAFT', 'DELETED'];

    const entryStatusFilter = computed((): BcxTableFilter => ({
      style: 'dropdown',
      visibleStates: [...entryStatusFilterStates],
      states: [...entryStatusFilterStates],
      labelCallback: (state: string) => `${t(`${state}`)}`,
      stateCallback: (states: string[], { status }: BlogForm) => states.includes(status),
    }));

    const statusFilterValues = useLocalStorage('blog-entries-table-status-filter', [...entryStatusFilterStates]);

    const statusFilterSortOptions: BcxTableSearchFilterSortOptions = reactiveComputed(() => ({
      filter: entryStatusFilter.value,
      states: statusFilterValues.value
    }));

    // TYPE FILTER
    const entryTypeFilterStates = ['ARTICLE', 'BC_NEWS', 'HELP_ARTICLE', 'DIRECT_MAIL', 'DIRECT_MAIL_TEST'];

    const entryTypeFilter = computed((): BcxTableFilter => ({
      style: 'dropdown',
      visibleStates: [...entryTypeFilterStates],
      states: [...entryTypeFilterStates],
      labelCallback: (state: string) => `${t(`${state}`)}`,
      stateCallback: (states: string[], { type }: BlogForm) => states.includes(type),
    }));

    const typeFilterValues = useLocalStorage('blog-entries-table-type-filter', [...entryTypeFilterStates]);

    const typeFilterSortOptions: BcxTableSearchFilterSortOptions = reactiveComputed(() => ({
      filter: entryTypeFilter.value,
      states: typeFilterValues.value
    }));

    const finalRows = computed(() => {
      // Apply both filters consecutively
      const { finalRows: filteredRows } = useBcxTableSearchFilterSort(statusFilterSortOptions, ref(entries.value.blogEntries));
      const { finalRows } = useBcxTableSearchFilterSort(typeFilterSortOptions, filteredRows);
      return finalRows.value;
    }) as ComputedRef<BlogForm[]>;
    // #endregion

    const sortValueCallback = (field:string, obj:BlogForm) => {
      switch (field) {
        case 'type': return obj.type;
        case 'title': return obj.title;
        case 'status': return obj.status;
        case 'lastUpdated': return obj.lastUpdated;
        case 'position': return obj.position;
        case 'audience': return obj.audience;
      }
      return 0;
    };

    return {
      entryStatusFilter,
      entryTypeFilter,
      statusFilterValues,
      typeFilterValues,
      sortData,
      tableHead,
      sortStateByKey,
      sortActive,
      sortValueCallback,
      sortableList,
      getAudience,
      finalRows,
      t
    };
  },
  methods: {
    editEntry(entry: BlogForm) {
      this.$emit('edit', entry);
    }
  }
});
