import { BlogEntry } from '@/views/blog/models/Blog';
import { orderBy, shuffle } from 'lodash';

export default function prepareBlog(entries: BlogEntry[], type: string) {
  const entriesToDisplay = entries.filter((entry: BlogEntry) => entry.type === type);

  let featuredEntries = entriesToDisplay.filter((article) => article.position === 1);
  let otherEntries = entriesToDisplay.filter((article) => article.position !== 1);

  if (featuredEntries.length === 0 && otherEntries.length > 0) {
    // No featured entries, pick one at random from the other entries
    const [randomFeatured, ...remainingEntries] = shuffle(otherEntries);
    featuredEntries = [randomFeatured];
    otherEntries = remainingEntries;
  } else if (featuredEntries.length > 1) {
    // Multiple featured entries, pick one at random
    const [selectedFeatured, ...demotedEntries] = shuffle(featuredEntries);
    featuredEntries = [selectedFeatured];
    otherEntries = [...demotedEntries, ...otherEntries];
  }

  return [...featuredEntries, ...orderBy(otherEntries, 'publishDate', 'desc')];
}
