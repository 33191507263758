import fileConverter from '@/mixins/fileConverter';
import { ref } from 'vue';
import useConfigState from '@/state/configState';

const UPLOAD_ERROR_TOO_BIG = 'too_big';
const UPLOAD_ERROR_WRONG_TYPE = 'wrong_type';

const useFileUpload = (allowedMimeTypes:string[] = [], maxFileSizeFromProps?: number) => {
  const input = document.createElement('input');
  input.type = 'file';

  const { registrationConfig } = useConfigState();

  const maxFileSize = maxFileSizeFromProps || registrationConfig.value['platform.server.max_request_size_limit'];
  const maxFileSizeMB = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 0 }).format(Math.floor(maxFileSize / 1000000));

  const fileContents = ref<string>('');
  const fileName = ref<string>('');
  const mimeType = ref<string>('');
  const error = ref<string>('');

  input.onchange = async (e) => {
    error.value = '';
    fileContents.value = '';
    mimeType.value = '';
    fileName.value = '';
    const file:File = (e.target as any).files[0];

    if (file.size > maxFileSize) {
      error.value = UPLOAD_ERROR_TOO_BIG;
    } else {
      const {
        string,
        mime,
      } = await fileConverter.methods.fileToString(file);
      if (allowedMimeTypes.length && (!mime || !allowedMimeTypes.includes(mime))) {
        error.value = UPLOAD_ERROR_WRONG_TYPE;
      } else {
        fileContents.value = string;
        mimeType.value = mime ?? '';
        fileName.value = file.name;
      }
    }
  };

  const upload = () => {
    error.value = '';
    input.value = '';
    input.click();
  };

  return {
    upload,
    fileContents,
    fileName,
    mimeType,
    error,
    maxFileSize,
    maxFileSizeMB,
  };
};

export default useFileUpload;
