interface StringFromFile {
  string: string;
  mime: string | undefined;
}

export default {
  methods: {
    fileToString(file: File): Promise<StringFromFile> {
      return new Promise((resolve, reject) => {
        const readerArrayBuffer = new FileReader();
        const readerBase64 = new FileReader();
        let arrayBuffer:ArrayBuffer;
        let base64:string;

        const ready = () => {
          const mime = this.getMimeType(arrayBuffer);

          const string = base64.substring(
            base64.indexOf('base64,') + 7,
          );

          resolve({
            string,
            mime,
          });
        };

        const isReady = () => {
          if (arrayBuffer && base64) ready();
        };

        readerArrayBuffer.onload = () => {
          arrayBuffer = readerArrayBuffer.result as ArrayBuffer;
          isReady();
        };
        readerBase64.onload = () => {
          base64 = readerBase64.result as string;
          isReady();
        };

        readerArrayBuffer.onerror = reject;
        readerBase64.onerror = reject;
        readerBase64.readAsDataURL(file);
        readerArrayBuffer.readAsArrayBuffer(file);
      });
    },

    getMimeType(arrayBuffer: ArrayBuffer): string | undefined {
      const hex = this.getFileSignature(arrayBuffer);

      // ATTENTION: Be careful when adding new signatures, because sometimes you'll
      // get false positives. Always check https://en.wikipedia.org/wiki/List_of_file_signatures
      // first (e.g. ODT and ZIP have same signature, but we might only want ODT and not ZIP).

      // We intentionally don't handle "D0CF11E0"
      // because it is only used for .msi files (installers, dangerous!)
      // and .doc, .xls, .ppt that are older than 2007, meaning we trade off
      // support for older files for security.

      switch (true) {
        case hex.startsWith('89504E47'):
          return 'image/png';
        case hex.startsWith('25504446'):
          return 'application/pdf';
        case hex.startsWith('FFD8FF'):
          return 'image/jpeg';
        case hex.startsWith('424D'):
          return 'image/bmp';
        case hex.startsWith('504B0304'): // DOCX, XLSX, PPTX, ODT, ZIP, JAR
          return this.tryGuessMimeTypeOfZip(arrayBuffer);
        case hex.startsWith('7B5C7274'):
          return 'application/rtf';
        case hex.startsWith('49492A00') || hex.startsWith('4D4D002A'):
          return 'image/tiff';
        case hex.startsWith('52494646'):
          return 'image/webp';
        default:
          return undefined;
      }
    },

    tryGuessMimeTypeOfZip(arrayBuffer: ArrayBuffer) {
      const zip = new TextDecoder().decode(arrayBuffer);

      if (zip.includes('[Content_Types].xml')) {
        return 'application/vnd.openxmlformats-officedocument'; // Office files (docx, pptx, xlsx)
      }

      if (zip.includes('META-INF/MANIFEST.MF')) {
        return 'application/java-archive'; // JAR file
      }

      if (zip.includes('mimetype')) {
        return 'application/vnd.oasis.opendocument.text'; // ODT file
      }

      return 'application/zip'; // Regular ZIP file
    },

    getFileSignature(arrayBuffer: ArrayBuffer) {
      const dataView = new DataView(arrayBuffer);
      return dataView.getUint32(0, false).toString(16).toUpperCase();
    },
  },
};
